import * as React from "react";
import { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import DataService from "@utils/DataService";
import * as styles from "./JobPosting.module.scss";
import ApplicationContactForm from "./ApplicationContactForm";

import compensationIcon from "@images/job_application/compensation.svg";
import contractIcon from "@images/job_application/contract.svg";
// import dropdown from "@images/job_application/dropdown.svg";
import experienceIcon from "@images/job_application/experience.svg";
import locationIcon from "@images/job_application/location.svg";

const Info = ({ title, content }) => {
  return (
    title && content ? <li>
      <h2>{title}</h2>
      <ul>
        {content && content.map((data, i) => (
          <li key={i} className={styles.bullet}>
            {data.name}
          </li>
        ))}
      </ul>
    </li> : <></>
  );
};

const JobPostingComponent = (posting) => {

  function createMarkup(content) {
    return {
      __html: content
    };
  }

  const {
    benefits,
    compensation,
    contract_type,
    duties,
    experience_level,
    id,
    image,
    job_description,
    job_title,
    location,
    requirements
  } = posting;

  const jobSpecs = [
    {
      title: "Location",
      icon: locationIcon,
      content: location
    },
    {
      title: "Contract Type",
      icon: contractIcon,
      content: contract_type
    },
    {
      title: "Experience Level",
      icon: experienceIcon,
      content: experience_level
    },
    {
      title: "Compensation",
      icon: compensationIcon,
      content: compensation
    },
  ];

  const jobInfo = [
    {
      title: "Requirements",
      content: requirements
    },
    {
      title: "What you will do",
      content: duties
    },
    {
      title: "Benefits",
      content: benefits
    }
  ]

  return (
    <div className={`${styles.job_application} container`}>
      <h1>{job_title}</h1>
      <section className={styles.job_data}>
        <section className={styles.description}>
          <h2>Job description</h2>
          <p dangerouslySetInnerHTML={createMarkup(job_description)}></p>
        </section>

        <article className={styles.img_and_specs}>
          <img src={image} className={styles.job_img} alt="Job Description" />

          <div className={styles.job_specs}>
            {jobSpecs.map(data => (
              <div key={data.title}>
                <p className={styles.spec_title}>
                  {data.title}
                  <span>
                    <img src={data.icon} alt={`${data.title} icon`} />
                  </span>
                </p>
                <p>{data.content}</p>
              </div>
            ))}
          </div>
          <h3 className={styles.questions}>Still have questions or need our help? Feel free to email us at support@magyk.cloud</h3>

        </article>

        <ul className={styles.job_info}>
          {jobInfo.map((info, i) => (
            <Info key={i} {...info} />
          ))}
        </ul>
      </section>

      <section>
        <h2 className={styles.apply}>Apply Now!</h2>
        <ApplicationContactForm />
      </section>
    </div>
  );
};

export default JobPostingComponent;
