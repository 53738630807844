// extracted by mini-css-extract-plugin
export const job_application = "JobPosting-module--job_application--3Id7f";
export const job_img = "JobPosting-module--job_img--8wzdJ";
export const job_specs = "JobPosting-module--job_specs--22DKN";
export const spec_title = "JobPosting-module--spec_title--3VcOg";
export const job_info = "JobPosting-module--job_info--EsDbG";
export const bullet = "JobPosting-module--bullet--3Vv0N";
export const apply = "JobPosting-module--apply--1Jbxv";
export const questions = "JobPosting-module--questions--3M5kE";
export const contact_means_call = "JobPosting-module--contact_means_call--1ZIv2";
export const form_img_container = "JobPosting-module--form_img_container--jJfcs";
export const file = "JobPosting-module--file--3o7fC";
export const file_label = "JobPosting-module--file_label--29WX3";
export const english_select = "JobPosting-module--english_select--2TX92";
export const contact = "JobPosting-module--contact--3aeTP";
export const form_grid = "JobPosting-module--form_grid--BqzPf";
export const form_inputs = "JobPosting-module--form_inputs--3F3IQ";
export const email = "JobPosting-module--email--1z6vX";
export const submit = "JobPosting-module--submit--18ZNW";
export const submit_button = "JobPosting-module--submit_button--1pIJ4";
export const job_data = "JobPosting-module--job_data--34mLo";
export const img_and_specs = "JobPosting-module--img_and_specs--1HH3z";
export const bouncy = "JobPosting-module--bouncy--oII8a";
export const pop = "JobPosting-module--pop--mSwyp";