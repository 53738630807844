import * as React from "react";
import * as styles from "../JobPosting.module.scss";
import PreferCall from "@components/ContactForm/PreferCall";

import attach from "@images/job_application/attach.svg";

const ApplicationContactForm = () => (
  <div className={styles.contact}>
    <form
      className={styles.form_grid}
      method="post"
      action="https://formspree.io/f/mvodgvjw"
      enctype="multipart/form-data"
    >
      <input type="text" required name="FirstName" placeholder="First Name*" />
      <input type="text" required name="LastName" placeholder="Last Name*" />
      <input type="text" required name="Telephone" placeholder="Telephone" />
      <input type="email" required name="Email" placeholder="Email*" />
      {/* <label for="resume" className={styles.file_label}>
        Attach Resume*
        <input
          className={styles.file}
          type="file"
          name="Resume"
          id="resume"
          required
          accept=".doc, .docx, .odt, .pdf"
        />
      </label> */}
      <input
        type="text"
        name="LinkedinProfile/Portfolio"
        placeholder="Linkedin Profile / Portfolio"
      />
      <label for="english-proficiency" className={styles.english_select}>
        <p>English Proficiency*</p>
        <select name="EnglishProficiency" id="english-proficiency" required>
          <option value="level">Choose...</option>
          <option value="basic">Basic</option>
          <option value="intermediate">Intermediate</option>
          <option value="fluid">Fluid</option>
          <option value="professional">Professional</option>
          <option value="native">Native</option>
        </select>
      </label>
      <textarea placeholder="Cover Letter" name="CoverLetter" />
      <input
        type="submit"
        className={`${styles.submit_button} orange_button`}
        value="Apply Now"
      ></input>
    </form>

    <section className={styles.contact_means_call}>
      <PreferCall />
    </section>
  </div>
);

export default ApplicationContactForm;
