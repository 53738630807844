import * as React from "react";
import * as styles from "./PreferCall.module.scss";

import logo from "@images/index_page/logo1.svg";

const PreferCall = () => {
  return (
    <div className={styles.contact_means_call}>
      <img src={logo} className={styles.form_img_container} alt="magyk top-hat rabbit"></img>
      <h4 style={{ fontSize: "400", textAlign: "center" }}>
        If you prefer, send us an <br /> email or give us a call:
      </h4>
      <p>
        <a href="mailto:sales@magyk.cloud">sales@magyk.cloud</a>
      </p>
      <p>
        <a href="tel:+13072631888">+1 307 2631 888</a>
      </p>
    </div>
  );
};

export default PreferCall;
