import * as React from "react";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import SEO from "@components/seo";

import JobPostingComponent from "@components/JobPosting";

const ArticlePage = props => {
  const posting = props.data.jobPostings;

  return (
    <>
      <Layout>
        <SEO title="Work With Us" />

        <JobPostingComponent {...posting} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query getSingleJobPosting($id: String) {
    jobPostings(id: { eq: $id }) {
      compensation
      contract_type
      duties {
        name
      }
      experience_level
      id
      image
      job_description
      job_title
      location
      requirements {
        name
      }
    }
  }
`;

export default ArticlePage;
